body,
input,
select,
option,
.fonts-loaded body,
.fonts-loaded input,
.fonts-loaded select,
.fonts-loaded option {
// input, button, select, textarea,
// // textarea, input[type="text"], input[type="password"], input[type="datetime"], input[type="datetime-local"], input[type="date"], input[type="month"], input[type="time"], input[type="week"], input[type="number"], input[type="email"], input[type="url"], input[type="search"], input[type="tel"], input[type="color"], .uneditable-input,
// select,
// .btn-primary,
// #basketcount span {
  font-family: $base-font;
  font-size: $fs-base;
}

body,
.fonts-loaded body {
// input, button, select, textarea,
// select {
  color: $text;
}

.ui-widget,
.ui-widget a {
    font-family: $base-font;
}


a {
    color: $blue;

    &:visited {
        color: $blue;
    }

    &:hover, &:focus {
        color: $red;
    }

    .breadcrumb &,
    .dropdown-menu & {
        color: $dark;
    }

    #moresearches &,
    #catalogue_detail_biblio &,
    #bibliodescriptions &,
    .ui-tabs .ui-state-default &,
    #opaccredits &,
    .title_summary &,
    #search-facets &,
    #didyoumean &,
    #ulactioncontainer &,
    #userbasket &,
    .searchresults & {
        color: $dark;

        &:link {
            color: $dark;
        }

        i.fa {
            color: $dark;
        }

        &:hover, &:focus {
            color: $red;
            // background: $light !important;

            i.fa {
                color: $red;
            }
        }
    }

    &.title,
    &.author {
    //     color: $dark;
    //
        &:hover, &:focus {
    //         color: $red;
            text-decoration: none;
        }
    }

    .pagination & {
        color: $dark;

        i.fa {
            color: $dark;
        }

        &:hover, &:focus {
            color: $red;
            background: $light !important;
            box-shadow: none;

            i.fa {
                color: $red;
            }
        }
    }

    #listResults li & {
        background: none;

        &:hover {
            background: none;
        }
    }

    #listResults li.highlight & {
        color: $red;
    }

//
//     .pagination .active & {
//         color: $dark !important;
//         background: $light !important;
//     }
//
    // #action & {
    //     font-weight: inherit;
    //     color: $dark;
    //
    //     &:hover, &:focus {
    //         color: $red;
    //     }
    // }
//
//     #action .dropdown-menu & {
//         &:hover, &:focus {
//             color: #fff;
//         }
//     }
//
//     i.fa {
//         color: inherit !important;
//     }
//
//     &:hover, &:focus {
//         i.fa {
//             color: inherit !important;
//         }
//     }
//
//
//     .ui-tabs .ui-tabs-nav li.ui-tabs-active & {
//         color: $dark;
//         font-weight: inherit;
//     }
//
//     .ui-tabs .ui-state-default & {
//         color: $dark;
//         font-weight: inherit;
//
//         &:link,
//         &:visited {
//             color: $dark;
//         }
//
//         &:hover {
//             color: $red;
//         }
//
//     }
//
    // .checkall &,
    // .clearall &,
    // .addto & {
    // // #facetcontainer &,
    // // &#CheckAll,
    // // &#CheckNone {
    //     color: $dark;
    //
    //     &:hover, &:focus {
    //         color: $red;
    //     }
    // }

//     .links & {
//         font-weight: 400;
//     }
}

button {
    &.buttons-print {
        color: $dark;

        &:before {
            color: $dark;
        }

        &:hover, &:focus {
            color: $red;

            &:before {
                color: $red;
            }
        }
    }
}


//
// .navbar .nav > li > a {
//     text-shadow: none;
// }
//
h1,
h2 {
// #logo {
    font-family: $header-font;
    font-weight: 400;
}

h1 {
    color: #333;
}

h2 {
    &.title {
        color: $text;
        margin-top: 1em;
        margin-bottom: 0.75em;
    }
}

#header-region {
    .navbar-brand {
        font-size: 18px;
        line-height: 140%;
        text-shadow: none;
    }

    .navbar-nav > li > a {
        color: rgba(255,255,255,.75);

        .fa.fa-icon-black, .fa.fa-icon-white {
            color: rgba(255,255,255,.75);
            margin-right: 5px;
        }

        &:hover, &:focus {
            color: #fff;

            .fa.fa-icon-black, .fa.fa-icon-white {
                color: #fff;
            }

        }
    }
//     .nav > li > a {
//         // color: #fff;
//         color: rgba(255,255,255,.75);
//         // color: #7c9eb2;
//     }
//
//
//     .nav li.dropdown > .dropdown-toggle .caret {
//         // border-top-color: #fff;
//         // border-bottom-color: #fff;
//         border-top-color: rgba(255,255,255,.75);
//         border-bottom-color: rgba(255,255,255,.75);
//     }
//
//     .navbar-inner li > a:focus,
//     .navbar-inner li > a:hover,
//     .navbar-inner li.dropdown.open > .dropdown-toggle {
//         // color: $red;
//         color: #fff;
//
//         .caret {
//             // border-top-color: $red !important;
//             // border-bottom-color: $red !important;
//             border-top-color: #fff !important;
//             border-bottom-color: #fff !important;
//         }
//
//         .fa.fa-icon-black, .fa.fa-icon-white {
//             // color: $red;
//             color: #fff;
//         }
//     }
}
//
// .mastheadsearch {
//     label {
//         color: inherit;
//         font-weight: inherit;
//         font-size: inherit;
//         padding-right: 10px;
//     }
// }
//
//
.btn,
a.btn {
    color: #fff;
//     text-shadow: none;
//
    &:hover, &:focus, &:active, &.active, &.disabled {
        color: #fff;
    }

    // &:hover, &:focus,
//
//     &.remove {
//         &:hover, &:focus, &:active, &.active, &.disabled {
//             color: #fff !important;
//         }
//     }

    #action & {
    // .actions &,
    // .list-actions & {
        font-weight: 400;
        color: $dark;
        margin-bottom: 0.25em;

        &:hover, &:focus, &:active, &.active, &.disabled {
            color: $red;

            i.fa {
                color: $red;
            }
        }

        i.fa {
            color: $dark;
        }
    }

    &.btn-lg,
    &.btn-sm,
    #download-cart &,
    &.remove {
        padding: .125rem 0.5rem;
    }

    &.btn-sm {
        #selections-toolbar & {
            padding: .125rem 0.5rem;
        }
    }

    .view &,
    #toolbar &,
    .actions &,
    .checkall &,
    .clearall &,
    .addto &,
    #selections-toolbar & {
        color: $dark;

        i.fa {
            color: $dark;
        }

        &:hover, &:focus, &:active, &.active, &.disabled {
            color: $red;

            i.fa {
                color: $red;
            }
        }
    }

    .actions:first-child & {
        &.btn-link {
            padding-left: 0.5rem
        }
    }

    &.rsssearchlink {
        vertical-align: text-top;
        margin-left: 1em;
        padding: 0;
        // background: transparent;
    }

}
//
// select, textarea, input[type="text"], input[type="password"], input[type="datetime"], input[type="datetime-local"], input[type="date"], input[type="month"], input[type="time"], input[type="week"], input[type="number"], input[type="email"], input[type="url"], input[type="search"], input[type="tel"], input[type="color"], .uneditable-input,
// .input-append select,
// option {
//     font-size: $fs-base;
//     color: $text;
// }
//
#basketcount span {
    background: $red;
    margin: 0 0 0 .5em
}
//
// .breadcrumb > li {
//     text-shadow: none;
// }
//
.sep {
    text-shadow: none;
    color: transparent;
}
//
// a.cartRemove {
//     font-size: inherit;
// }
//
#opaccredits {
    text-align: center;
    font-size: 14px;
    font-family: 'Roboto Slab', serif;
}
//
// .table {
//     font-size: inherit;
//
//     .item-status {
//         display: inline;
//         font-size: inherit;
//         margin: 0;
//     }
// }
//
// .view {
//     color: $text;
//     a, span {
//         font-size: $fs-base;
//     }
// }
//
// .results_summary {
//     font-size: inherit;
//     color: $text;
// }
//
#social_networks span,
.available {
    color: $text;
}

#didyoumean {
    font-size: 85%;
}

#numresults {
    color: $text;
}

//
// // jquery ui
// #opac-detail,
// #usershelves {
//
//     .ui-widget {
//         font-size: $fs-base;
//         font-family: $base-font;;
//     }
//
//     .ui-helper-reset {
//         font-size: $fs-base;
//         font-family: $base-font;;
//     }
// }
//
// // #action a i.fa, #action button i.fa, .actions a i.fa, .actions button i.fa, .toolbar a i.fa, .toolbar button i.fa, .view a i.fa, .view button i.fa
//
// // https://fontawesome.com/license
