body {
    background: #fff;
}

#wrapper {
// #wrap {
    padding-left: 0;
    padding-right: 0;
//
//     > .container-fluid {
//         padding-left: 0;
//         padding-right: 0;
//     }
}

#opac-main-search {
    background: transparent;
//     .mastheadsearch {
//         margin: 0;
//         padding: 10px 20px 0 20px;;
//         // background: #fff;
//         // border-bottom: 1px solid $dark;
//         background: transparent;
//
//         label {
//             // display: none;
//         }
//     }
//
//     .span12 {
//         background: $light;
//     }
//
//     .transl1 {
//         max-width: 400px;
//     }
//
//     text-align: center;
//
//     #searchform {
//
//     }
}

.navbar {
    .navbar-brand {
        color: #fff;
        font-weight: 400;
    }
//     .navbar-inner {
        background: $dark;
//     }
}
//
#header-region {
//     text-align: right;
//
//     #logo {
//         display: inline-block;
//         float: left !important;
//         text-align: left;
//     }
//
//
//     ul.nav {
//         margin-top: 8px;
//         display: inline-block;
//         // vertical-align: middle;
//         float: none !important;
//     }
//
    #members {
        display: none;
    }
//
//     .dropdown {
//         text-align: left;
//     }
    + .container-fluid {
        background: $light;
    }
}
//
// #logo,
// #logo a {
//     width: auto;
// }
//
#logo {
    background: none;
    // margin: 10px 0;
    width: 315px;
    height: 50px;
    display: block;

    a {
        position: relative;
        // display: inline-block;
        display: block;
        height: 100% !important;
        // height: 50px;
        padding: 0 0 0 55px;
        width: 100%;
        white-space: normal !important;

        &:before {
            content: '';
            display: inline-block;
            // margin-left: -40px;
            width: 40px;
            height: 40px;
            // outline: 1px solid #ccc;
            background: url('../img/book-open-r.svg') no-repeat center;
            // background: url('https://bibliothek.apabiz.de/static/book-reader.svg') no-repeat center;
            background-size: contain;
            // https://raw.githubusercontent.com/FortAwesome/Font-Awesome/master/svgs/solid/book-open.svg
            position: absolute;
            top: 5px;
            left: 0;
        }
    }
}

#cart-list-nav {
    justify-content: flex-end;

    #cartmenulink {
        margin-right: 20px;
    }

    .dropdown-menu {
        left: auto;
        right: 0;

        .dropdown-divider,
        a[href$="category=1"],
        a[href$="add_form"] {
            display: none;
        }
    }

}

//
// .navbar-inverse  {
//     .divider-vertical {
//         border: 0;
//     }
// }
//
//
// .dropdown-menu > li > a:focus,
// .dropdown-menu > li > a:hover {
//     background: $red;
// }
//
.main {
//     margin: 30px 0;
//     padding-left: 30px;
//     padding-right: 30px;
    border: 0;
//
    .breadcrumb {
        margin-top: 1em;
        margin-bottom: 2em;
    }
//
//     > .container-fluid {
//         padding-left: 0;
//         padding-right: 0;
//     }
}

.breadcrumb {
    background: $light;
    border-radius: .25rem;
}

#opacmainuserblock {
    max-width: 800px;
    margin-left: auto;
    margin-right: auto;
}

#opaccredits {
    background: $light;
    padding: 1em 2em;

}
//
// // search/results page
// #toolbar.toolbar,
// #toolbar.toolbar.clearfix {
//     background: $light;
//
//     // #selections-toolbar,
//     // #selections-toolbar {
//     //     background: $light2;
//     // }
// }
//
// .floating {
//     box-shadow: none;
// }
//
// .pagination {
//     ul {
//         box-shadow: none;
//
//         > li > a,
//         > li > span {
//             border-color: $dark;
//         }
//     }
// }
//
// .toolbar li:first-child a {
//     border-left: 1px solid $dark;
// }
//
// .table-striped tbody > tr:nth-child(2n+1) > td,
// .table-striped tbody > tr:nth-child(2n+1) > th {
//     background: transparent;
// }
//
// #search-facets {
//     border-radius: 4px;
//     border-color: $light;
//
//     h4 a {
//         border: 0;
//         background: $light;
//     }
// }
//
// .available {
//     strong {
//         display: none;
//     }
//
//     > span {
//         display: block;
//     }
// }
//
// .unavailable {
//     display: block;
// }
//
//
// // single result view
//
#catalogue_detail_biblio {
    #views {
        border-bottom: 1px solid $light;

        .view {
            border: 1px solid $light;
            border-bottom: 0;
            background: $light;
            border-radius: 4px 4px 0 0;
            margin-bottom: -1px;

            span, a {
                display: inline-block;
                padding: 5px 10px !important;
            }

            &.current-view {
            //     // border: 1px solid $light !important;
                background: #fff;
            //     margin-bottom: -5px;
            }

            // .fa {
            //     display: none;
            // }
        }

    }
}
//
// .no-image {
//     display: none;
// }
//
#ulactioncontainer {
    #action,
    #social_networks,
    .nav_results {
        border-radius: 4px;
        background: $light;
        // background: transparent;
        border: 0;
        // border-color: $light;
    }

    .nav_results {
        .l_Results {
            background: transparent;
        }

        .left_results,
        .back_results,
        .right_results {
            width: 33.33%;
        }

        .pg_menu {
            background-color: transparent;
            border-top: 0;
        }

        .pg_menu li a,
        .pg_menu li span {
            float: none;
            background-color: transparent;
            border: 0;
        }
    }
}
//
// #bibliodescriptions {
//     margin-top: 3em;
// }
//
#opac-detail,
#usershelves {
    .ui-corner-top {
        border-radius: 4px 4px 0 0;
    }

    .ui-tabs .ui-tabs-nav {
        padding: 0;
    }

    .ui-tabs .ui-tabs-nav li {
        border-color: $light;
        background: $light;
        margin-bottom: -1px;
    }

    .ui-tabs .ui-tabs-nav li.ui-tabs-active {
        border-color: $light;
        background: #fff;
        // border-color: #aa0000 !important;
    }

    .ui-tabs .ui-tabs-panel {
        border: 0;
        border-top: 1px solid $light;
        padding-left: 0;
        padding-right: 0;
    }
}

.table {
    th {
        background-color: $light !important
    }

    th, td {
        vertical-align: middle;
    }

    .sorting,
    .sorting_asc,
    .sorting_desc {
        background-position: center right;
    }

}
//
// #bookcover {
//     float: right;
//     margin: 3em 0 0 1em;
//
//     img {
//         margin: 0;
//     }
// }
//
//
// #social_networks {
//     span {
//         padding-left: .5em;
//     }
//
//     iframe {
//         padding: .5em;
//     }
// }
//
// #holdingst {
//     .status,
//     .date_due {
//         display: none;
//     }
// }
//
// #tab_comments {
//     display: none;
// }
