@media screen and (min-width: 568px) {
}


@media (min-width:992px) {
    .main {
        margin-left: 30px;
        margin-right: 30px;
    }
}


@media screen and (min-width: 1024px) {
}


@media screen and (min-width: 1280px) {
}
