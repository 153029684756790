// textarea, input[type="text"], input[type="password"], input[type="datetime"], input[type="datetime-local"], input[type="date"], input[type="month"], input[type="time"], input[type="week"], input[type="number"], input[type="email"], input[type="url"], input[type="search"], input[type="tel"], input[type="color"], .uneditable-input {
.form-control, input[type="password"], input[type="text"], select, textarea {
    border-color: #ccc;
//     border: 1px solid #ccc;
//     -webkit-box-shadow: none;
//     -moz-box-shadow: none;
//     box-shadow: none;
//
    &:focus {
          border-color: $dark;
          box-shadow: none;
    }
}
//
.btn,
.btn-primary {
    border-color: $dark;
    background: $dark;
    // -webkit-box-shadow: none;
    // -moz-box-shadow: none;
    // box-shadow: none;
//     -webkit-border-radius: 4px;
//     -moz-border-radius: 4px;
//     border-radius: 4px;
//
    &:hover, &:focus, &:active, &.active, &.disabled {
    // &:active, &:hover {
        border-color: $red;
        background: $red;
        -webkit-box-shadow: none;
        -moz-box-shadow: none;
        box-shadow: none;
    }

    #action &,
    .actions &,
    // .list-actions &,
    #toolbar &,
    #selections-toolbar &,
    .clearall &,
    .addto & {
        background: transparent;

        &:hover, &:focus, &:active, &.active, &.disabled {
            background: transparent;
        }
    }

    &.rsssearchlink {
        background: transparent;
        border: 0;
    }

//     &.addtocart,
//     &.cartRemove {
//         padding-left: 0.5em !important;
//         padding-right: 0.5em !important;
//     }
//
//     &.rsssearchlink {
//         background: transparent;
//         border-color: transparent;
//         padding: 0 20px;
//
//         &:hover, &:focus, &:active, &.active, &.disabled {
//             background: transparent;
//             border-color: transparent;
//         }
//     }

    .view & {
        background: transparent;
        border: 0;
        padding: 0;

    }
//
//     #ulactioncontainer & {
//         background: transparent;
//     }
}




// #loginModal input[type="submit"]:hover {
//     background: $red;
// }
